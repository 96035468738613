@import "shared/styles/variables";

$padding: 20px;
$size: 60px;

.listOfModels {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: $padding;
  padding: $padding;
  .itemList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: $padding;
    text-decoration: none;
    width: $size * 3;
    height: $size * 3;
    text-align: center;
    background-color: white;
    border: 1px solid $greyColor;
    border-radius: 24px;
    .imgWrap {
      width: $size * 2;
      height: $size * 2;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .label {
      color: black;
      font-size: 12px;
      line-height: 1.7;
    }
  }
  @media screen and (max-width: 480px) {
    gap: $padding / 2;
    padding: $padding / 2;
    .itemList {
      padding: $padding / 2;
      width: $size * 2;
      height: $size * 2.5;
      .imgWrap {
        width: $size;
        height: $size;
      }
    }
  }
}
