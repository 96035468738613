@import "shared/styles/variables";

$padding: 12px;

.wrapper {
  position: fixed;
  left: $widthNavigation;
  top: 0;
  bottom: 0;
  background-color: white;
  width: $widthSidebarStrategiesTreeModel;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: $padding;
  padding: $padding;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $padding;
    border: 1px solid #d7d7d7;
    width: calc($widthSidebarStrategiesTreeModel - ($padding * 2));
    height: calc($widthSidebarStrategiesTreeModel - ($padding * 2));
    box-sizing: border-box;
    &.current {
      border-color: $primaryColor;
    }
    .imgWrap {
      width: calc($widthSidebarStrategiesTreeModel - ($padding * 4));
      height: calc($widthSidebarStrategiesTreeModel - ($padding * 4));
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media screen and (max-width: 480px) {
    left: $widthNavigation * 2 / 3;
    width: $widthSidebarStrategiesTreeModel / 2;
    padding: $padding / 2;
    .btn {
      padding: $padding / 2;
      width: calc(($widthSidebarStrategiesTreeModel / 2) - ($padding));
      height: calc(($widthSidebarStrategiesTreeModel / 2) - ($padding));
      .imgWrap {
        width: calc(($widthSidebarStrategiesTreeModel / 2) - ($padding * 2));
        height: calc(($widthSidebarStrategiesTreeModel / 2) - ($padding * 2));
      }
    }
  }
}
