@import "shared/styles/variables";

$padding: 20px;

.pageWrap {
  flex: 1;
  padding: $padding;
  padding-left: $widthSidebarSWOTModel + $padding * 2;
  @media screen and (max-width: 480px) {
    padding: $padding / 2;
    padding-left: ($widthSidebarSWOTModel / 2) + $padding * 1.75;
  }
}
