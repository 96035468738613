@import "shared/styles/variables";

.contentWrap {
    flex: 1;
    .container {
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        .title {
            font-size: 16px;
            .strong {
                color: $primaryColor;
            }
        }
        .subtitle {
            font-size: 13px;
        }
        .form {
            width: 100%;
            max-width: 480px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .formControl {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .label {
                    font-size: 13px;
                    font-weight: 500;
                }
                .input {
                    border: 1px solid $greyColor;
                    padding: 8px 12px;
                    font-size: 13px;
                    border-radius: 4px;
                    &:focus {
                        outline: none;
                    }
                }
            }
            .btn {
                color: white;
                background-color: $primaryColor;
                padding: 8px 12px;
                border-radius: 4px;
                border: 0;
                outline: none;
                box-shadow: none;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
}