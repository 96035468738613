@import "shared/styles/variables";

$padding: 20px;

.pageWrap {
  padding: $padding;
  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    .box {
      outline: 1px solid black;
      background-color: white;
      .header {
        padding: $padding / 4;
        background-color: $primaryColor;
        border-bottom: 1px solid black;
        > h3 {
          font-size: 14px;
          text-align: center;
          margin: 0;
          text-transform: uppercase;
          color: white;
        }
      }
      .content {
        padding: $padding/2;
        .list {
          list-style: circle;
          margin: 0;
          padding-left: $padding;
          li {
            color: $primaryColor;
            > span {
              color: black;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    padding: $padding / 2;
    .container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      .box {
        .content {
          padding: $padding/4;
        }
      }
    }
  }
}
