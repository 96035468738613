@import "shared/styles/variables";

.wrapper {
  overflow: auto;
  :global(.konvajs-content) {
    margin: 0 auto;
    box-sizing: border-box;
    min-height: 100vh;
  }
}
