@import "shared/styles/variables";

@mixin center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: fixed;
  width: $widthNavigation;
  height: 100%;
  background-color: $primaryColor;
  z-index: 100;
  .container {
    @include center;
    height: 100%;
    .navs {
      @include center;
      gap: 20px;
      .btn {
        @include center;
        border: 0;
        background-color: transparent;
        outline: none;
        color: #fff;
        padding: 8px;
        border-radius: 12px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &.current {
          background-color: white;
          color: $primaryColor;
          pointer-events: none;
        }
        > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    width: $widthNavigation * 2 / 3;
    .container {
      .navs {
        .btn {
          padding: 4px;
          > svg {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
  }
}
